
import { Component, Vue } from 'vue-property-decorator'
import { apiNoticeData } from "@/api/setting"
import { RequestPaging } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
@Component({
  components: {
    LsPagination
  }
})
export default class Buyers extends Vue {
  /** Data Start **/
  formData = {
    recipient: '1'
  }
  pager: RequestPaging = new RequestPaging()
  /** Data End **/

  /** Methods Start **/
  getNoticeList() {
    this.pager
      .request({
        callback: apiNoticeData,
        params: this.formData
      })
      .catch(() => {
        this.$message.error('数据请求失败，刷新重载!')
      })
  }

  changeTabs() {
    this.getNoticeList()
  }
  /** Methods End **/

  /** LifeCycle Start **/
  created() {
    this.getNoticeList()
  }
  /** LifeCycle End **/
}
